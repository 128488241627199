<template>
  <van-popup
    v-model="visible"
    :close-on-click-overlay="closeOnClickOverlay"
    @close="cancel"
    safe-area-inset-bottom
  >
    <div class="popup-content">
      <div class="popup-view">
        <div class="popup-head">
          {{ title }}
          <van-icon
            v-if="close"
            @click="cancel"
            class="close"
            name="cross"
            color="#333"
            size="20"
          />
        </div>
        <div class="popup-box">
          <div class="popup-content-text" v-if="type == 'text'">
            {{ text }}
            <slot name="text" />
          </div>
          <div class="popup-content-stepper" v-if="type == 'stepper'">
            <van-stepper
              :disabled="luckdraw"
              :class="{ stepper: luckdraw }"
              v-model="stepper"
              :min="min"
              :max="20"
              input-width="70"
              button-size="42"
            />
            <div class="popup-content-text" v-if="text">
              {{ text }}
            </div>
          </div>
          <div class="popup-content-input" v-if="type == 'input'">
            <van-field
              v-model="number"
              type="number"
              :border="false"
              center
              input-align="center"
            />
            <div class="popup-content-text" v-if="text">
              {{ text }}
            </div>
          </div>
          <div class="popup-content-address" v-if="type == 'address'">
            <van-field v-model="name" placeholder="Name" />
            <van-field v-model="phone" type="tel" placeholder="Mobile number" />
            <van-field v-model="tgnumber" placeholder="Telegram ID" />
            <van-field v-model="email" placeholder="Email address" />
            <van-field
              v-model="address"
              rows="2"
              autosize
              type="textarea"
              placeholder="Receiver’s address"
            />
            <div class="form-tip">
              *Remember to buy the shipping box before purchasing the PNFT.
            </div>
          </div>
          <div class="popup-content-draw" v-if="type == 'draw'">
            <van-radio-group v-model="draw_type">
              <van-cell-group>
                <van-cell
                  :title="item.label"
                  clickable
                  @click="draw_sessions(item)"
                  v-for="(item, index) in draw_sessions_arr"
                  :key="index"
                >
                  <template #right-icon>
                    <van-radio :name="item.value" />
                  </template>
                </van-cell>
              </van-cell-group>
            </van-radio-group>
          </div>
        </div>
      </div>
      <div class="popup-button" v-if="button">
        <!-- <div class="popup-cancel" v-if="cancelShow" @click="cancel">cancel</div> -->
        <div class="popup-confirm" v-if="confirmShow" @click="confirm">
          {{ $t("common.confirm") }}
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
    close: {
      type: Boolean,
      default() {
        return false;
      },
    },
    closeOnClickOverlay: {
      type: Boolean,
      default() {
        return false;
      },
    },
    button: {
      type: Boolean,
      default() {
        return true;
      },
    },
    cancelShow: {
      type: Boolean,
      default() {
        return true;
      },
    },
    confirmShow: {
      type: Boolean,
      default() {
        return true;
      },
    },
    title: {
      type: String,
      default() {
        return "";
      },
    },

    text: {
      type: String,
      default() {
        return "";
      },
    },
    identifier: {
      type: String,
      default() {
        return "";
      },
    },
    /**
     * text
     * input
     * stepper
     * address
     * draw
     */
    type: {
      type: String,
      default() {
        return "input";
      },
    },
    min: {
      type: Number,
      default() {
        return 1;
      },
    },
    //是否抽奖
    luckdraw: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      visible: false,
      stepper: 1,
      number: 1,

      name: "",
      phone: "",
      address: "",
      tgnumber: "",
      email: "",

      draw_sessions_arr: [
        {
          label: "10Ac USDT",
          value: 10,
          id: 1,
        },
        // {
        //   label: "500USDT(ACOIN)",
        //   value: 500,
        //   id: 2,
        // },
        // {
        //   label: "1000USDT(ACOIN)",
        //   value: 1000,
        //   id: 3,
        // },
      ],
      draw_type: "",
      draw_id: "",
    };
  },
  watch: {
    /**
     * 监听显示
     */
    show: {
      handler(val) {
        this.visible = val;
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    shows() {
      this.visible = true;
    },
    draw_sessions(item) {
      this.draw_type = item.value;
      this.draw_id = item.id;
    },
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      if (this.type == "text") {
        this.$emit("confirm", {
          type: this.type,
        });
      }
      if (this.type == "input") {
        this.$emit("confirm", {
          type: this.type,
          data: this.number,
        });
      }
      if (this.type == "stepper") {
        this.$emit("confirm", {
          type: this.type,
          data: this.stepper,
        });
      }
      if (this.type == "address") {
        if (this.name === "") {
          this.$toast.fail("Please enter the name of the consignee");
          return;
        }
        if (this.phone === "") {
          this.$toast.fail("Please enter the mobile number of the consignee");
          return;
        }
        if (this.tgnumber === "") {
          this.$toast.fail("Please enter TG address");
          return;
        }
        if (this.email === "") {
          this.$toast.fail("Please enter email address");
          return;
        }
        if (this.address === "") {
          this.$toast.fail("Please enter the shipping address");
          return;
        }
        this.$emit("confirm", {
          type: this.type,
          data: {
            name: this.name,
            phone: this.phone,
            address: this.address,
            tgnumber: this.tgnumber,
            email: this.email,
          },
        });
      }
      if (this.type == "draw") {
        if (this.draw_sessions === "") {
          // this.$toast.fail("请选择场次");
          this.$toast.fail("Please select the session");
          return;
        }
        this.$emit("confirm", {
          type: this.type,
          identifier: this.identifier,
          data: {
            draw_type: this.draw_type,
            draw_id: this.draw_id,
          },
        });
      }
      this.number = 1;
      this.name = "";
      this.phone = "";
      this.address = "";
      this.draw_type = "";
      this.draw_id = "";
      this.tgnumber = "";
      this.email = "";
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .stepper {
  .van-stepper__plus,
  .van-stepper__minus {
    display: none;
  }

  .van-stepper__input {
    width: 200px !important;
  }
}

.van-popup {
  background: none;
}

.popup-content {
  width: calc(100vw - 40px);
  overflow: hidden;
  border-radius: 20px;

  .popup-view {
    background-color: #fff;
    padding: 0 33px;

    .popup-head {
      height: 107px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 39px;
      font-weight: 500;
      color: #B48418;
      border-bottom: 1px solid #b7b7b7;
      position: relative;
      text-align: center;

      .close {
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
      }
    }

    .popup-box {
      padding: 45px 0;

      .popup-content-text {
        text-align: center;
        font-size: 31px;
        font-weight: 400;
        color: #a0a2a5;
      }

      .popup-content-stepper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .popup-content-text {
          padding-top: 23px;
        }

        /deep/ .van-stepper {
          .van-stepper__minus,
          .van-stepper__plus {
            background-color: #ffa647;
            border-radius: 6px;

            &::before {
              background-color: #fff;
              height: 2px;
              width: 14px;
            }

            &::after {
              background-color: #fff;
              height: 14px;
              width: 2px;
            }

            &.van-stepper__minus--disabled {
              background-color: #ffc99c;
            }
          }

          .van-stepper__input {
            margin: 0 10px;
            border-radius: 6px;
            background-color: #f0f0f0;
            font-size: 27px;
            font-weight: bold;
            color: #191919;
            line-height: 1;
          }
        }
      }

      .popup-content-input {
        /deep/ .van-cell {
          background-color: #f0f0f0;
          border-radius: 8px;
          padding: 0;
          height: 42px;

          .van-cell__value {
            height: 100%;

            .van-field__body {
              height: 100%;

              .van-field__control {
                height: 100%;
                font-size: 27px;
                font-weight: bold;
                color: #191919;
              }
            }
          }
        }

        .popup-content-text {
          padding-top: 23px;
        }
      }
    }
  }

  .popup-button {
    height: 104px;
    display: flex;

    > * {
      width: 100%;
      height: 100%;
      font-size: 34px;
      font-weight: 400;
      // color: #ffffff;
      color: #4A5D6C;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .popup-cancel {
      background-color: #9ea9ff;
    }

    .popup-confirm {
      // background: linear-gradient(90deg, #77D63C, #68DD75);
      background-color: #fdd79b;
    }
  }
}

.popup-content-draw {
  padding: 0 30px;
}

.form-tip {
  padding: 30px 10px 0;
  font-size: 18px;
  color: #f78e26;
}
</style>
