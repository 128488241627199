<template>
  <div class="container">
    <div class="banner">
      <img src="@/assets/images/home/banner.png" alt="" />
    </div>
    <div class="address">
      {{ $t("common.walletAddress") }}:
      {{ set_walletAddressHide(walletAddress) }}
    </div>
    <div class="main">
      <div class="title">
        {{ $t("home.introduction") }}
        <!-- <van-count-down
          @finish="finish"
          v-if="!is_buy"
          class="van-count-down"
          :format="`DD ${$t('common.day')} HH ${$t('common.hour')} mm ${$t('common.minute')} ss ${$t('common.second')}`"
          :time="count_down_time"
        /> -->
      </div>
      <p>{{ $t("home.introductionText") }}</p>
      <!-- <div class="currentPrice">
        <div class="label">
          <img src="@/assets/images/gold-coin.png" alt="" />
          {{ $t("home.currentPrice") }}
        </div>
        <div class="value">{{ unitPrice }} U</div>
      </div>
      <button
        class="max-button"
        style="margin-top: 100px"
        v-if="!is_buy"
        type="button"
        @click="popup_input_show = true"
      >
        {{ $t("common.buy") }}
      </button>-->
      <!-- <button class="max-button disabled" style="margin-top:100px" v-else type="button">购买</button> -->
    </div>
    <!-- loading start -->
    <loading :loading="is_loading" text="Loading..." />
    <!-- loading end -->
    <popup-input
      type="input"
      :title="$t('common.number')"
      @confirm="popup_input_confirm"
      :show="popup_input_show"
      close
      @cancel="popup_input_show = false"
    />
  </div>
</template>
<!-- 支付usdt -->
<script>
import { mapState } from "vuex";
import {
  usdcTokenAddress,
  simucollectionAddress,
  getChainId,
  getTokenBalance,
  busdTransferAccounts,
  getGasLimit,
} from "@/common/metaMask/index";
import loading from "@/components/loading";
import popupInput from "@/components/popup/input";
import { getHomeData } from "@/request/api";
import { walletAddressHide } from "@/common/utils";
export default {
  name: "Home",
  components: { loading, popupInput },
  computed: {
    ...mapState({
      walletAddress: (state) => state.user.walletAddress,
    }),
  },
  data() {
    return {
      is_loading: false,
      count_down_time: 0,
      number: 1,
      max_number: 0,
      popup_input_show: false,
      is_buy: false,
      setI: null,
      unitPrice: 0.3,
    };
  },
  mounted() {
    setTimeout(() => {
      this.getData();
    }, 500);
  },
  // beforeRouteLeave(to, from, next) {
  //   next();
  //   clearInterval(this.setI);
  //   this.setI = null;
  // },
  beforeDestroy() {
    if (this.setI) {
      clearInterval(this.setI);
      this.setI = null;
    }
  },
  methods: {
    //倒计时结束
    finish() {
      this.is_buy = true;
    },
    set_walletAddressHide(str) {
      return walletAddressHide(str);
    },
    /**
     * 获取数据
     */
    getData() {
      this.setI = setInterval(() => {
        getHomeData().then((res) => {
          if (res.code == 200) {
            this.is_buy = res.data.endTime <= 0;
            this.count_down_time = res.data.endTime * 1000;
            this.max_number =
              res.data.platformcc > res.data.simumcc
                ? res.data.simumcc
                : res.data.platformcc;
          } else {
            // this.$toast.fail(res.msg);
          }
        });
      }, 1000);
    },
    /**
     * 窗口确认回调
     * @param {*} e
     */
    popup_input_confirm(e) {
      if (Number(e.data) < 1) {
        this.$notify({
          type: "warning",
          message: `${this.$t("warning.min")} 1`,
        });
        return;
      }
      if (Number(e.data) > this.max_number) {
        this.$notify({
          type: "warning",
          message: `${this.$t("warning.max")} ${this.max_number}`,
        });
        return;
      }
      this.number = this.unitPrice * Number(e.data);
      this.buy();
      this.popup_input_show = false;
    },
    /**
     * 购买
     */
    async buy() {
      const GasLimit = await getGasLimit(
        this.walletAddress,
        usdcTokenAddress,
        simucollectionAddress,
        Number(this.number)
      );
      this.is_loading = true;
      getChainId().then((res) => {
        if (parseInt(res) != 56) {
          this.is_loading = false;
          this.$notify({
            type: "warning",
            message: this.$t("warning.chainError"),
          });
          return;
        }
        getTokenBalance(this.walletAddress, usdcTokenAddress).then((res) => {
          if (Number(res) < this.number) {
            this.is_loading = false;
            this.$notify({
              type: "warning",
              message: this.$t("warning.balance"),
            });
          } else {
            busdTransferAccounts(
              this.walletAddress,
              usdcTokenAddress,
              simucollectionAddress,
              this.number,
              GasLimit
            )
              .then((txid) => {
                this.is_loading = false;
                this.getData();
                // console.log(txid)
                // asyncGetTransactionByHash(txid).then(res => {
                //   if (res) {
                //     this.getData()
                //   }
                // })
                if (txid) {
                  this.$notify({
                    type: "success",
                    message: this.$t("warning.paymentSuccessful"),
                  });
                } else {
                  this.$notify({
                    type: "danger",
                    message: this.$t("warning.paymentFailed"),
                  });
                }
              })
              .catch(() => {
                this.is_loading = false;
                this.$notify({
                  type: "danger",
                  message: this.$t("warning.paymentFailed"),
                });
              });
          }
        });
      });
    },
    routerPush(path) {
      this.$router.push({ path });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/style/home.scss";

.van-count-down {
  color: #b48418;
}
</style>
